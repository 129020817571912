import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import Icon from "../../utilities/icons"
import { SideInfoBox } from "../../components/styles/SideInfoBox"
import SEO from "../../components/seo"

const ShellacPage = () => {
  return (
    <Layout>
      <div class="standard-page-container">
        <SEO
          title="Shellac Newark | Beauty Gems"
          description="Mobile beauty therapist, offering Shellac in Newark, Claypole, Fernwood, Winthorpe, Coddington, Collingham, Elston, Muskham and other surrounding areas."
        />
        <div className="inner-container">
          <h1>Shellac</h1>
          <div className="two-column-layout-wrapper">
            <div className="two-column-layout-item_wide ">
              <p>
                CND Shellac™ is the original Power Polish service, with 14+ days
                of superior color, no matter what life brings you. Practical and
                fearless improve your nails with an armor coat that says, "see
                you in 2 weeks".
              </p>
              <p>
                Shellac is a polish that goes on like nail polish but wears like
                a gel. When applied it looks like nail polish but is cured under
                a UV lamp to dry. Shellac should last between 2 and 3 weeks with
                no chipping, just growing out.
              </p>
              <p>
                Shellac application is available in a range of colours as well
                as French. It is perfect for holidays as no touching up will be
                required meaning you can leave your polishes at home! For more
                information visit the official Shellac site.
              </p>
              <p>
                The other <Link to="/treatments">treatments</Link> I offer are
                on the treatments page, please do not hesitate to contact me if
                you would like to know more about a treatment, for example what
                is involved and what products are used. If you would like to
                book a treatment you can use the contact form or phone or text
                me on 07796 252618.
              </p>
              <div
                style={{ marginTop: "40px", marginBottom: "40px" }}
                className="global-btn-wrapper "
              >
                <Link
                  className="global-btn global-btn_blue"
                  to="/contact"
                  state={{ treatment: "shellac" }}
                >
                  Book Appointment
                </Link>
              </div>
            </div>
            <div className="two-column-layout-item_narrow">
              <SideInfoBox className="global-box-shadow">
                <iframe
                  width="100%"
                  src="https://www.youtube.com/embed/L_nZl3EIsjk"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  style={{ marginBottom: 0 }}
                />
              </SideInfoBox>
              <SideInfoBox className="global-box-shadow">
                <h3 className="sidebox-title">Multiple Colours Available</h3>
                <p>
                  Choose from over 60 colours! If there's a colour you've seen
                  that you'd like, please mention when booking and I can arrange
                  to get this colour in if I don't already have it!
                </p>
              </SideInfoBox>
              <SideInfoBox className="global-box-shadow">
                <h3 className="sidebox-title">Vouchers Available</h3>
                <p>
                  You can purchase vouchers from me, which are great for that
                  special occasion; birthdays, Christmas and anniversaries. A
                  simple but thoughtful and effective gift for that special
                  person in your life.
                </p>
              </SideInfoBox>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ShellacPage
